import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';

/**
 * Page
 */
const ImpressumPage = () => {
	return (
		<Layout page="impressum">
			<SEO title="" />
			<Menu selected="impressum" />
			<div className="page-width align-left spacing-normal spacing-huge-top">
				<section>
					<h1>Pflichtangaben nach § 5 Telemediengesetz (TMG)</h1>
					<p>
						Viktoria Burkhardt Grafik Design (Firma)
						<br />
						Inhaberin: Viktoria Burkhardt
						<br />
						Lilienthalstraße 47
						<br />
						66117 Saarbrücken
					</p>

					<p>Tel.: 0176 63 14 34 61</p>

					<p>Steueridentifikationsnummer: DE312742730</p>

					<p>
						Hier geht es zu meiner{' '}
						<Link to="/datenschutz/">Datenschutzerklärung</Link>
					</p>
				</section>

				<section>
					<h1>Urheberrecht am Inhalt dieser Webseite</h1>
					<p>
						Alle innerhalb der Domain https://vb-grafikdesign.de/
						veröffentlichten Inhalte sind urheberrechtlich
						geschützt. Eine Verwertung der Inhalte ohne mein
						ausdrückliches schriftliches Einverständnis ist
						unzulässig.
					</p>
				</section>

				<section>
					<h1>Externe Links</h1>

					<p>
						Diese Website enthält Verknüpfungen zu Websites Dritter
						("externe Links"). Diese Websites unterliegen der
						Haftung der jeweiligen Betreiber. Ich habe bei der
						erstmaligen Verknüpfung der externen Links die fremden
						Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
						bestehen.
					</p>

					<p>
						Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich.
						Ich habe keinerlei Einfluss auf die aktuelle und
						zukünftige Gestaltung und auf die Inhalte der
						verknüpften Seiten. Das Setzen von externen Links
						bedeutet nicht, dass ich mir die hinter dem Verweis oder
						Link liegenden Inhalte zu Eigen mache.
					</p>
					<p>
						Eine ständige Kontrolle dieser externen Links ist für
						mich ohne konkrete Hinweise auf Rechtsverstöße nicht
						zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch
						derartige externe Links unverzüglich gelöscht.
					</p>
					<p>
						Für illegale, unkorrekte oder unvollständige Inhalte
						sowie für Schäden, die durch die Nutzung der
						Informationen der dortigen Websites entstehen, haftet
						allein der Betreiber der Internetseite, auf die verlinkt
						wurde. Eine Haftung durch den bloßen Hinweis auf diese
						Websites ist ausgeschlossen.
					</p>
				</section>

				<section>
					<h1>Hinweis:</h1>
					<p>
						Die EU-Kommission bietet die Möglichkeit zur
						Online-Streitbeilegung auf einer von ihr betriebenen
						Online-Plattform. Diese Plattform ist über den externen
						Link{' '}
						<a
							href="https://ec.europa.eu/consumers/odr/main/"
							target="_blank"
							rel="noreferrer"
						>
							https://ec.europa.eu/consumers/odr/main/
						</a>{' '}
						zu erreichen. Zu einer Teilnahme an einem
						Schlichtungsverfahren bin ich nicht verpflichtet, da
						über diesen Internetauftritt kein Handel mit Waren oder
						Dienstleistungen stattfindet. Deshalb kann ich die
						Teilnahme an einem solchen Verfahren leider auch nicht
						anbieten.
					</p>
				</section>
			</div>
		</Layout>
	);
};

export default ImpressumPage;
